<template>
  <div>
    <b-overlay :show="show" rounded="sm">
      <div>
        <b-card no-body>
          <b-card-body>
            <b-row>
              <b-col cols="12" md="4" class="mb-md-0 mb-1 d-flex justify-content-between align-items-center">
                <label style="width: 100px;font-size:1.1rem">时间区间</label>
                <flat-pickr
                  id="start_date"
                  v-model="searchParams.start_date"
                  :config="{ enableTime: false, onChange: createDate, mode: 'range', dateFormat: 'Y-m-d',locale: locale}"
                  class="form-control"
                />
              </b-col>
              <b-col cols="12" md="5" class="mb-md-0 mb-1 d-flex justify-content-between align-items-center">
                <label style="width: 100px;font-size:1.1rem">项目名称</label>
                <v-select
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  v-model="searchParams.projectId"
                  :options="projectOptions"
                  class="w-100"
                  label="name"
                  :reduce="val => val.id"
                >
                  <template v-slot:no-options>没有找到相关信息</template>
                </v-select>
              </b-col>
              <b-col class="mb-md-0 d-flex justify-content-end align-items-end mb-1" v-if="$can('export', 'extension-report-element')">
                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-primary" @click="exportData"> 导出 </b-button>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>

        <b-card class="mb-1">
          <vxe-table ref="xTable" border :auto-resize="true" :data="tableData"
            highlight-current-row :loading="loading" header-row-class-name="false"
            :scroll-x="{enabled: true}" :scroll-y="{enabled: true}"
          >
            <vxe-column type="seq" width="80"></vxe-column>
            <vxe-table-column field="projectName" title="项目" min-width="220"></vxe-table-column>
            <vxe-table-column field="contentTypeName" title="要素名称" min-width="320" show-overflow></vxe-table-column>
            <vxe-table-column field="allViews" title="分享次数" min-width="100" show-overflow></vxe-table-column>
            <vxe-table-column field="allForwords" title="被转发次数" min-width="100" show-overflow></vxe-table-column>
            <vxe-table-column field="allComments" title="留言次数" min-width="100"></vxe-table-column>
            <vxe-table-column field="allClueCount" title="线索数" min-width="100">
              <template v-slot="{ row }" v-if="$can('info', 'extension-report-element')">
                <div @click="watchCustomer(row)">
                  <span class="mr-1">{{row.allClueCount}}</span>
                  <span><feather-icon icon="EyeIcon" size="18" /></span>
                </div>
              </template>
            </vxe-table-column>
          </vxe-table>

          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <Select v-model="searchParams.size" class="mr-1">
                <Option v-for="item in pageOptions" :value="item.value" :key="item.value">{{ item.label }}</Option>
              </Select>
              <span class="text-nowrap"> 共 {{ total }} 条记录 </span>
            </div>
            <div class="d-flex align-items-center">
              <b-pagination v-model="searchParams.page" :total-rows="total" :per-page="searchParams.size" first-number last-number align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0">
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
              <div class="d-flex align-items-center mb-0 mt-1 ml-1">
                <span class="text-nowrap">跳至</span>
                <Input @on-enter="pageToChange"
                  v-model="pageTo" type="number" number class="mx-1" style="width: 5rem;"/>
                <span class="text-nowrap">页</span>
              </div>
            </div>
          </div>
        </b-card>
      </div>
    </b-overlay>

    <modal v-model="shallShowModal" :content-type="contentType" :type-id="typeId" />
  </div>

</template>

<script>

import vSelect from 'vue-select'
import { ref, watch, onMounted } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import { Mandarin } from 'flatpickr/dist/l10n/zh.js'

import { reportListProject, reportProjectList } from '@/api/report/report.js'
import modal from './modal.vue'

// import { apiBaseURL } from '@zlssdt'
import { $urlConfig } from '@themeConfig'
import request from '@/auth/jwt/useJwt'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  directives: {
    Ripple,
  },
  components: {
    modal,
    vSelect,
    flatPickr,
  },
  setup(props, context) {
    /* 必要组件加载区域 */

    // 权限组件 弹出确认组件
    const { $can, $swal } = context.root

    // 消息提醒组件
    const toast = useToast()
    // 日期汉化组件
    const locale = Mandarin

    /* 组件页面数据准备区域，包括表单，下拉选择项，数据整理操作等 */
    // 表格加载控制 表格数据
    const loading = ref(false)
    // 接口返回速度控制页面
    const show = ref(false)
    // 列表记录 记录总数
    const total = ref(0)
    const tableData = ref([])
    // 分页查询选择项
    const pageOptions = ref([
      { label: '10 条/页', value: 10 },
      { label: '20 条/页', value: 20 },
      { label: '50 条/页', value: 50 },
    ])

    // 表格对象DOM
    const xTable = ref(null)

    // 查询条件
    const searchParams = ref({ page: 1, size: 20, endTime: '', startTime: '', projectId: 0 })

    // 页面跳转到
    const pageTo = ref(null)
    // 处理页面跳转到 用户输入, 判断数据是否介入 1 ~ 最大数
    const pageToChange = () => {
      const count = Math.ceil(total.value / searchParams.value.size)
      if (pageTo.value < 1) {
        pageTo.value = 1
      } else if (pageTo.value > count) {
        pageTo.value = count
      } else {
        pageTo.value = parseInt(pageTo.value, 0)
      }

      searchParams.value.page = pageTo.value
    }

    // 处理查看用户
    const shallShowModal = ref(false)
    const contentType = ref('')
    const typeId = ref('')
    const watchCustomer = row => {
      typeId.value = row.typeId
      contentType.value = row.contentType
      shallShowModal.value = true
    }

    /* 接口调用区域 */

    // 条件筛选 下拉选择分类 选择项
    const projectOptions = ref([])
    const getProjectList = () => {
      reportProjectList()
        .then(res => {
          projectOptions.value = res.data
        })
        .catch(err => {
          toast({
            component: ToastificationContent,
            props: { title: '获取员工列表失败', text: err.msg, icon: 'AlertTriangleIcon', variant: 'danger' },
          })
        })
    }

    // 获取列表
    const fetchData = () => {
      show.value = true
      reportListProject(searchParams.value)
        .then(res => {
          tableData.value = res.data.list
          total.value = res.data.total
          show.value = false
        })
        .catch(err => {
          toast({
            component: ToastificationContent,
            props: { title: '获取项目要素推广汇总报表失败', text: err.msg, icon: 'AlertTriangleIcon', variant: 'danger' },
          })
        })
    }

    // 导出
    const exportData = () => {
      toast({
        component: ToastificationContent,
        props: {
          title: '项目要素推广汇总导出',
          text: '正在导出项目要素推广汇总EXCEL文件,请稍等!',
          icon: 'DownloadCloudIcon',
          variant: 'info'
        },
      })

      show.value = true

      fetch(
        `${$urlConfig.apiBaseURL}/report/listProject?endTime=${searchParams.value.endTime}&startTime=${searchParams.value.startTime}&projectId=${searchParams.value.projectId}&isExport=true`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            // 'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: `${request.jwtConfig.tokenType} ${request.getToken()}`,
          },
          mode: 'cors',
        }
      )
        .then(response => {
          show.value = false
          response.blob().then(blob => {
            const link = document.createElement('a')
            link.style.display = 'none'
            // a 标签的 download 属性就是下载下来的文件名
            link.download = `项目要素推广汇总-${new Date().toLocaleDateString()}.xlsx`
            link.href = URL.createObjectURL(blob)
            document.body.appendChild(link)
            link.click()
            // 释放的 URL 对象以及移除 a 标签
            URL.revokeObjectURL(link.href)
            document.body.removeChild(link)
          })
        })
    }

    /* 组件内部逻辑处理，生命周期问题处理区域 */

    // 统计时间段区间处理成查询条件
    const createDate = selectedDates => {
      if (selectedDates.length === 0) {
        searchParams.value.startTime = ''
        searchParams.value.endTime = ''
      } else if (selectedDates.length === 1) {
        searchParams.value.startTime = selectedDates[0].toLocaleDateString()
        searchParams.value.endTime = ''
      } else if (selectedDates.length === 2) {
        searchParams.value.startTime = selectedDates[0].toLocaleDateString()
        searchParams.value.endTime = selectedDates[1].toLocaleDateString()
      }
    }

    // 页面加载 请求数据
    onMounted(() => {
      if ($can('report', 'extension-report-element')) {
        fetchData()
      } else {
        toast({
          component: ToastificationContent,
          props: { title: '您没有报表数据列表的权限', icon: 'AlertTriangleIcon', variant: 'danger' },
        })
      }
      if ($can('select', 'extension-report-element')) {
        getProjectList()
      } else {
        toast({
          component: ToastificationContent,
          props: { title: '您没有报表项目下拉列表的权限', icon: 'AlertTriangleIcon', variant: 'danger' },
        })
      }
    })

    watch(searchParams.value, () => { fetchData() }) // 查询条件变更时 => 发起请求

    return {
      loading,
      tableData,
      xTable,
      show,
      total,

      searchParams,
      pageOptions,
      pageTo,
      pageToChange,

      projectOptions,

      fetchData,
      exportData,

      locale,
      createDate,
      watchCustomer,
      shallShowModal,
      contentType,
      typeId,
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.info {
  font-size: 1.1rem;
  height: 1.5;
  line-height: 1.5;
}
</style>
